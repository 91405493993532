<template>
  <div id="app">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    />
    <b-row
      class="flex justify-center overflow-x-scroll"
    >
      <b-col
        v-for="(column, key) in colunas"
        :key="key"
        size="4"
      >
        <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">
          {{ column.carregal.nome }}
        </p>
        <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
        <draggable
          :list="column.leads.data"
          :animation="300"
          ghost-class="ghost-card"
          group="tasks"
          @change="moveCard($event, column.carregal.id, column.leads.data)"
        >
          <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
          <task-card
            v-for="(task) in column.leads.data"
            :key="task.id"
            :task="task"
            class="mt-2 cursor-move"
          />
          <!-- </transition-group> -->
        </draggable>
        <b-button
          v-if="column.leads.next_page_url"
          variant="outline-secondary"
          class="mt-1"
          style="width: 100%"
          @click="carregaMaisLeads(column.leads.next_page_url, column.carregal.id)"
        >
          Carregar mais Leads
        </b-button>
      </b-col>
    </b-row>
    <!-------------------  ABRIR POPUP  ------------------->
    <b-modal
      id="kanban"
      ok-onlys
      header-bg-variant="warning"
      hide-footer
      size="lg"
      title="Confirme o Novo Status do Lead"
      ok-title="Accept"
    >

      <b-row>
        <b-col>
          <b-card-body>
            <dl class="row">
              <dt class="col-sm-3">
                Nome:
              </dt>
              <dd class="col-sm-9">
                {{ dados.nomecontato }}
              </dd>
            </dl>

            <dl
              v-if="dados.telefonecontato"
              class="row"
            >
              <dt class="col-sm-3">
                Telefone:
              </dt>
              <dd class="col-sm-9">
                {{ dados.telefonecontato }}
              </dd>
            </dl>
            <dl
              v-if="dados.emailcontato"
              class="row"
            >
              <dt class="col-sm-3">
                E-mail:
              </dt>
              <dd class="col-sm-9">
                {{ dados.emailcontato }}
              </dd>
            </dl>
            <dl
              v-if="dados.observacaolead"
              class="row"
            >
              <dt class="col-sm-3">
                Obs:
              </dt>
              <dd class="col-sm-9">
                {{ dados.observacaolead }}
              </dd>
            </dl>
            <dl
              v-if="dados.produtosinteresse"
              class="row"
            >
              <dt class="col-sm-3">
                Produto de Interesse:
              </dt>
              <dd class="col-sm-9">
                {{ dados.produtosinteresse }}
              </dd>
            </dl>
            <dl
              v-if="dados.utm_medium"
              class="row"
            >
              <dt class="col-sm-3">
                UTM Med:
              </dt>
              <dd class="col-sm-9">
                {{ dados.utm_medium }}
              </dd>
            </dl>
            <dl
              v-if="dados.utm_term"
              class="row"
            >
              <dt class="col-sm-3">
                UTM Term:
              </dt>
              <dd class="col-sm-9">
                {{ dados.utm_term }}
              </dd>
            </dl>
            <dl
              v-if="dados.melhorhorarioctt"
              class="row"
            >
              <dt class="col-sm-3">
                Horario:
              </dt>
              <dd class="col-sm-9">
                {{ dados.melhorhorarioctt }}
              </dd>
            </dl>
            <dl
              v-if="dados.utm_source"
              class="row"
            >
              <dt class="col-sm-3">
                UTM Orig:
              </dt>
              <dd class="col-sm-9">
                {{ dados.utm_source }}

              </dd>
            </dl>
            <dl
              v-if="dados.utm_campaign"
              class="row"
            >
              <dt class="col-sm-3">
                UTM Camp:
              </dt>
              <dd class="col-sm-9">
                {{ dados.utm_campaign }}
              </dd>
            </dl>
            <dl
              v-if="dados.utm_content"
              class="row"
            >
              <dt class="col-sm-3">
                UTM Cont:
              </dt>
              <dd class="col-sm-9">
                {{ dados.utm_content }}
              </dd>
            </dl>
          </b-card-body>

        </b-col>
      </b-row>
      <b-card-text>
        <span>Escreva o relatório do atendimento com esse Lead</span>
      </b-card-text>
      <b-form-textarea
        id="textarea-rows"
        v-model="relatorioLead"
        placeholder="Relatório do atendimento"
        rows="8"
        class="mb-2"
      />
      <hr>
      <b-button
        variant="primary"
        block
        @click="SalvarDados()"
      >
        Salvar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import draggable from 'vuedraggable'
import TaskCard from './components/TaskCard.vue'

export default {
  name: 'App',
  components: {
    Loading,
    TaskCard,
    draggable,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      colunas: [],
      relatorioLead: '',
      idLead: '',
      coluna: '',
      dados: [],
    }
  },
  created() {
    this.$http.get('admin/kanban/listaleads').then(resp => {
      this.colunas = resp.data
    })
  },
  methods: {
    verDados(item) {
      localStorage.setItem('lead_id', JSON.stringify(item))
      this.dados = JSON.parse(localStorage.getItem('lead_id'))
    },
    async carregaMaisLeads(url, id) {
      this.isLoading = true
      // const listaLeads = []
      await this.$http.get(url).then(resp => {
        const dados = resp.data[id]
        dados.leads.data.forEach(resposta => {
          this.colunas[id].leads.data.push(resposta)
        })
        this.colunas[id].leads.next_page_url = dados.leads.next_page_url
      })
      this.isLoading = false
    },
    // eslint-disable-next-line no-unused-vars
    moveCard(event, col, item) {
      // eslint-disable-next-line no-unused-vars
      if (event.added) {
        this.idLead = event.added.element.id
        this.coluna = col
        this.$bvModal.show('kanban')
        this.$http.get(`admin/leads/${this.idLead}`).then(resp => {
          localStorage.setItem('lead_id', JSON.stringify(resp.data.data))
        })
        const dad = JSON.parse(localStorage.getItem('lead_id'))
        // eslint-disable-next-line prefer-destructuring
        this.dados = dad[0]
      }
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'ALERTA',
        variant: cor,
        solid: true,
      })
    },
    SalvarDados() {
      if (this.relatorioLead.length < 20) {
        this.Notificacao('success', 'Escreva o relatório do atendimento')
        return
      }
      const obj = {
        observacao: this.relatorioLead,
        id: this.idLead,
        status_id: this.coluna,
      }
      this.$http.post(`admin/kanban/attkanban/${this.idLead}`, obj).then(retorna => {
        if (retorna.data.response === true) {
          this.Notificacao('success', retorna.data.mensagem)
        }
        if (retorna.data.response === false) {
          this.Notificacao('danger', retorna.data.mensagem)
        }
      })
      this.$bvModal.hide('kanban')
      this.relatorioLead = ''
    },
  },
}
</script>

<style>
.column-width {
  min-width: 320px;
  width: 320px;
}

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.bg-warning {
  background-color: #fec91a !important;
}

/*cor do botão*/
[dir] .btn-warning {
  border-color: #fec91a !important;
  background-color: #fec91a !important;
}

.btn-warning {
  color: #000 !important;
}
</style>

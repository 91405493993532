<template>
  <div class="bg-white shadow rounded px-1 pt-1 pb-1 border border-white">
    <div>
      <p>
        {{ task.nomecontato }}
      </p>
    </div>
    <div>
      <span>{{ corrigeData(task.created_at) }}</span>
      <badge
        v-if="task.type"
        :color="badgeColor"
      >
        {{ task.type }}
      </badge>
    </div>
  </div>
</template>
<script>
import Badge from './Badge.vue'

export default {
  components: {
    Badge,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    badgeColor() {
      const mappings = {
        Design: 'purple',
        'Feature Request': 'teal',
        Backend: 'blue',
        QA: 'green',
        default: 'teal',
      }
      return mappings[this.task.type] || mappings.default
    },
  },
  methods: {
    corrigeData(data) {
      const date = new Date(data)
      return date.toLocaleDateString('pt-BR')
    },
  },
}
</script>
